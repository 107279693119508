<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="发布最新研发"/>
		<NewestForm @eventForm="handleGetForm" class="purForm" />
	</div>
</template>

<script>
	import NewestForm from '@/components/Newest/Form'
	export default {
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addnews', form).then(data => {
					this.$message.success('发布成功');
					this.$router.push('/newest')
				})
			}
		},
		components: {
			NewestForm
		}
	}
</script>
